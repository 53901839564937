<template>
  <main v-click-outside="close">
    <div class="flex relative">
        <button @click="toggleFilterList = !toggleFilterList; toggleDatePicker = false" class="border py-3 flex group items-center px-5 hover:border-blue-700  hover:text-blue-700 cursor-pointer border-primaryBlue text-xs rounded-md text-primaryBlue font-medium">
            Date Filter
            <div>
            <svg
              class="w-4 h-4  ml-4 group-hover:text-blue-700"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24106 7.7459C4.53326 7.44784 4.99051 7.42074 5.31272 7.66461L5.40503 7.7459L12 14.4734L18.595 7.7459C18.8872 7.44784 19.3444 7.42074 19.6666 7.66461L19.7589 7.7459C20.0511 8.04396 20.0777 8.51037 19.8386 8.83904L19.7589 8.93321L12.582 16.2541C12.2898 16.5522 11.8325 16.5793 11.5103 16.3354L11.418 16.2541L4.24106 8.93321C3.91965 8.60534 3.91965 8.07376 4.24106 7.7459Z"
                fill="#253B95"
              />
            </svg>
          </div>
        </button>
    </div>
    <div v-show="toggleFilterList" class="bg-white rounded-md shadow-md border border-gray-200 py-2 absolute w-96">
        <button 
          class="flex w-full items-center py-2 px-5 hover:bg-gray-200"
          v-for="(range, index) in filterOptions"
          :key="index"
          @click="doFilter(range.name)"
        >
          <input type="checkbox" v-model="range.selected" :id="range.name" :value="range.name">
          <p class="text-sm  text-gray-700 text-opacity-75  uppercase cursor-pointer  group-hover:text-primaryBlue   ml-2 leading-6 font-medium">
            {{ range.label }}
          </p>
        </button>
    </div>
    <div v-if="toggleDatePicker" class="absolute">
      <date-picker
        ref="datepicker"
        v-model="customDateRange"
        class="shadow-md rounded-md"
        :inline="true"
        format='X'
        range
      ></date-picker>
    </div>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  data() {
    return {
      dateRanges: {
        today: {
          selected: false,
          label: 'Today'
        },
        yesterday: {
          selected: false,
          label: 'Yesterday'
        },
        pastWeek: {
          selected: false,
          label: 'Past Week'
        },
        thisMonth: {
          selected: false,
          label: 'This Month'
        },
        lastMonth: {
          selected: false,
          label: 'Last Month'
        },
        customRange: {
          selected: false,
          label: 'Date Filter'
        },
      },
      selectedDateRange: {
        startDate : 'today',
        endDate : 'yesterday'
      },
      toggleFilterList: false,
      customDateRange: null,
      toggleDatePicker: false
    };
  },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    preSelected: {
      type: String,
      default: 'today'
    }
  },
  computed: {
    filterOptions() {
      // console.log(Object.keys(this.dateRanges));
      return Object.keys(this.dateRanges).map((item) => {
        return {
          name: item,
          selected: ((item || '').toLowerCase() === (this.preSelected || '').toLowerCase()) ? true : this.dateRanges[item].selected,
          label: this.dateRanges[item].label
        };
      });
    },
  },
  directives: {
    ClickOutside,
  },
  components: {
    DatePicker,
  },
  watch: {
    customDateRange(newValue, oldValue) {
      this.selectedDateRange.startDate = moment(newValue[0]).valueOf()
      this.selectedDateRange.endDate = moment(newValue[1]).valueOf()
      console.log('Custom Date Format: ', this.selectedDateRange)
      this.emitSelectedDate()
    },
    preSelected(newValue, oldValue) {
      const findPropertyName = this.filterOptions.find(item => item.label === newValue) || null
      if(findPropertyName) {
        this.resetSelection()
        this.doFilter(findPropertyName.name)
      } else {
        this.resetSelection()
      }
    }
  },
  methods: {
    close() {
      this.toggleFilterList = false;
      this.toggleDatePicker = false;
    },
    emitSelectedDate() {
      this.$emit('updated', this.selectedDateRange)
    },
    emitLabelChange(label) {
      this.$emit('labelchange', label)
    },
    doFilter(filterRange = null) {
      let label = ''
      if(this.dateRanges[filterRange]) {
        this.resetSelection()
        this.dateRanges[filterRange].selected = true
        label = this.dateRanges[filterRange].label
      } else {
        console.warn(`filterRange '${filterRange}' does not exist`)
      }
      switch (filterRange) {
        case 'today':
          console.log('Today Was Set');
          this.selectedDateRange.startDate = moment().startOf('day').valueOf()
          this.selectedDateRange.endDate = moment().endOf('day').valueOf()
          this.emitSelectedDate()
          this.emitLabelChange(label)
          break;

        case 'yesterday':
          console.log('Yesterday Was Set');
          this.selectedDateRange.startDate = moment().subtract(1, 'days').startOf('day').valueOf()
          this.selectedDateRange.endDate = moment().subtract(1, 'days').endOf('day').valueOf()
          this.emitSelectedDate()
          this.emitLabelChange(label)
          break;

        case 'pastWeek':
          console.log('Past Week Was Set');
          this.selectedDateRange.startDate = moment().subtract(7, 'days').startOf('day').valueOf()
          this.selectedDateRange.endDate = moment().subtract(1, 'days').endOf('day').valueOf()
          this.emitSelectedDate()
          this.emitLabelChange(label)
          break;
        
        case 'thisMonth':
          console.log('This Month Was Set');
          this.selectedDateRange.startDate = moment().startOf('month').valueOf()
          this.selectedDateRange.endDate = moment().valueOf()
          this.emitSelectedDate()
          this.emitLabelChange(label)
          break;

        case 'lastMonth':
          console.log('Last Month Was Set');
          this.selectedDateRange.startDate = moment().subtract(1, 'months').startOf('month').valueOf()
          this.selectedDateRange.endDate = moment().subtract(1, 'months').endOf('month').valueOf()
          this.emitSelectedDate()
          this.emitLabelChange(label)
          break;

        case 'customRange':
          console.log('Custom Range Was Set!');
          this.emitLabelChange(label)
          this.toggleDatePicker = true
          break;

        default:
          console.log('No Matching filter. do nothing');
          // TODO: remember what to set if no matching function was found
          break;
      }
      console.log('set date: ', this.selectedDateRange)
    },
    resetSelection(){
      this.filterOptions.forEach((prop) => {
        this.dateRanges[prop.name].selected = false
      })
    }
  },
};
</script>

<style scoped></style>
