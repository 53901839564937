<template>
  <div>
    <trac-loading v-if="loading" />
    <!-- <div class="mb-2 mx-12 mt-12">
      <div @click="$router.go(-1)" class="mb-1 cursor-pointer text-sm font-light">&lt Back</div>
      <h1 class="font-bold">Noona Transactions</h1>
    </div> -->

    <!-- <div class="px-6 py-6 border rounded bg-white">
      No records
    </div> -->

    <div class="px-6 py-6 border rounded bg-white">
      <div
        class="flex gap-x-2 mb-6 flex-row bg-white items-center rounded justify-between"
      >
        <div class="flex big-shadow invisible">
          <span class="text-xs font-light text-primaryGray w-full">
            <input
              class="w-full py-2 px-4 outline-none"
              type="text"
              name=""
              id=""
              placeholder="Search"
            />
            <!-- v-model.trim="searchedProduct" -->
          </span>
          <img src="../../assets/svg/Search_icon.svg" class="mr-3" />
        </div>
        <div class="flex-1"></div>
        <trac-filter id="0" :key="0" :multi-select="false" :label="'Filter by Date'"  @dateChange="updateDateRange" />
        <trac-filter id="1" :key="1" :multi-select-list="servicesForFiltering" :multi-select="true" :label="'Filter by Service'" @multiListEmit="updateServices" />
        <trac-filter id="2" :key="2" :multi-select-list.sync="storesForFilter" :multi-select="true" :label="'Filter by Store'" @multiListEmit="updateStores" />
        <!-- <trac-filter :key="2" :multi-select-list="stores" :multi-select="true" :label="'Filter by Store'" @multiListEmit="updateStores" /> -->
      </div>
      <div v-if="!isEmpty" class="mb-8 border rounded-lg overflow-x-scroll">
        <table  class="w-full table-auto">
          <thead class="bg-blue-100">
            <tr>
              <th
                class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
              >
                Customer.
              </th>
              <th
                class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
              >
                Sales Price
              </th>
              <th
                class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
              >
                Amount Paid
              </th>
              <th
                class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
              >
                Balance to Pay
              </th>
              <th
                class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
              >
                Date
              </th>
              <th
                class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
              >
                Service Paid For
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(record, index) in transactions"
              :key="index"
              class="cursor-pointer hover:bg-gray-200"
            >
              <td class="text-xs whitespace-no-wrap p-4">
                {{ record.customer.name }}
              </td>
              <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
                {{ record.sales_price }}
              </td>
              <td class="text-xs whitespace-no-wrap p-4 font-medium">
                {{ record.amount_paid }}
              </td>
              <td class="text-xs whitespace-no-wrap p-4 font-medium capitalize">
                {{ record.balance_to_pay }}
              </td>
              <td class="text-xs whitespace-no-wrap p-4 font-medium">
                {{ record.date }}
              </td>
              <td class="text-xs whitespace-no-wrap p-4 font-medium">
                <span v-for="(service, index) in record.service" :key="index">
                  {{ service.title }}
                </span>
              </td>
              <!---->
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="px-6 py-6 border rounded bg-red-100">No records</div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import DateFilter from "../Payments/DateFilter_v2.vue";
import Stores from "../Payments/SelectedStores.vue";
import { GET_LOCAL_DB_DATA } from '../../browser-db-config/localStorage';

export default {
  components: {
    DateFilter,
    Stores,
  },
  data() {
    return {
      copied: false,
      loading: false,
      transactions: [],
      searchCriteria: {
        dateRange: {
          start_date:`${DateTime.now()
            .toUTC()
            .startOf("day")
            .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`,
          end_date: `${DateTime.now()
            .toUTC()
            .endOf("day")
            .startOf("second")
            .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`
        },
        services: [],
        stores: []
      },
      stores: [],
      services: []
    };
  },
  computed: {
    isEmpty() {
      return this.transactions.length < 1;
    },
    servicesList() {
      return this.services.map(item => item.category.services).flat()
    },
    storesForFilter() {
      return this.stores.map((item) => { return {id: item.id, name: item.name} })
    },
    servicesForFiltering() {
      return this.servicesList.map((item) => {return {id: item.id, name: item.title}} )
    }
  },
  created() {
    this.loadServices()
    this.loadTransactions();
  },
  mounted () {
    this.stores = this.getAssignedStoresFromSession()
  },
  methods: {
    getAssignedStoresFromSession() {
      const sessionData = GET_LOCAL_DB_DATA('traction-app-user-data')
      const stores = (sessionData || {user : {stores : []}}).user.stores || []
      // debugger
      return stores
    },
    loadServices() {
      this.$store.dispatch("NOONA_SERVICES").then((data) => {
        if (data.status) {
          this.services = data.data
        } else {
          this.services = []
        }
      })
    },
    updateServices(data) {
      const servicesId = data.map(item => item.id)
      this.searchCriteria.services = servicesId
      this.loadTransactions()
    },
    updateStores(data) {
      const stores = data.map(item => item.id)
      // debugger
      this.searchCriteria.stores = stores
      this.loadTransactions()
    },
    updateDateRange(data) {
      this.searchCriteria.dateRange.start_date = data.start
      this.searchCriteria.dateRange.end_date = data.end
      
      this.loadTransactions()
    },

    loadTransactions() {
      this.loading = true;
      this.$store
        .dispatch("NOONA_TRANSACTIONS", this.searchCriteria)
        .then((data) => {
          if (data.status) {
            // debugger
            this.transactions = data.data || [];
            this.loading = false;
          } else {
            this.transactions = [];
            this.loading = false;
          }
        });
    },
    doCopy(codeString) {
      this.copied = true;
      this.copyToClipboard(codeString);
      const resetAfterSomeTime = setTimeout(() => {
        this.copied = !this.copied;
      }, 3000);
    },
    copyToClipboard(codeString) {
      const onTheFlyTextArea = document.createElement("textarea");
      onTheFlyTextArea.value = codeString;
      document.body.appendChild(onTheFlyTextArea);
      onTheFlyTextArea.select();

      const copyOperation = document.execCommand("copy");
      if (copyOperation) {
        // console.log("Copied!");
        document.body.removeChild(onTheFlyTextArea);
        return Promise.resolve(true);
      } else {
        // console.warn("copy fail");
        return Promise.reject(new Error("Clipboard Copy Failed"));
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
table tbody tr:nth-child(even) {
  background: #F9F9F9;
}
.trim-box {
  background: #f6fcff;
  border: 0.3px dashed #253b95;
  border-radius: 5px;
}
</style>